<script>
    export default {
        name: "StaticWrapperWithPopup",
        props: {
            text: String,
            hideOthers: Boolean,
            hash: String,
            width: Number,
            show: {
                type: Boolean,
                default: true
            },
            left: {
                type: Boolean,
                default: false
            },
            top: {
                type: Boolean,
                default: false
            },
            right: {
                type: Boolean,
                default: false,
            },
            cursorDefault: Boolean
        },
        mounted() {
            if (this.isEducationModeEnabled) {
                if (this.$el.tagName === 'IMG')
                    this.$el.onload = () => this.setBounding()
                else
                    this.setBounding()
            }
        },
        watch: {
            isEducationModeEnabled(v) {
                if (v)
                    this.setBounding()
                else
                    this.unsetBounding()
            },
            show(v) {
                if (v)
                    this.setBounding()
                else
                    this.unsetBounding()
            }
        },
        methods: {
            setBounding() {
                if (this.show && this._isMounted) {
                    if (this.cursorDefault)
                        this.$el.style.cursor = 'default'
                    this.$store.dispatch('setStaticWrappedElementBounding', {
                        ...(this.hash && ({hash: this.hash})),
                        ...(this.width && ({width: this.width})),
                        text: this.text,
                        left: this.left,
                        top: this.top,
                        right: this.right,
                        bounding: this.$el.getBoundingClientRect()
                    })
                }
            },
            unsetBounding() {
                this.$el.style.cursor = 'initial'
                this.$store.dispatch('unsetStaticWrappedElementBounding', {text: this.text, ...(this.hash && ({hash: this.hash}))})
            },
            hide() {
                if (this.hideOthers)
                    this.$store.dispatch('cleanWrappedElementBounding')
                else
                    this.unsetBounding()
            }
        },
        render() {
            return this.$scopedSlots.default({
                hide: this.hide
            })
        }
    }
</script>